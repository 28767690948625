import React, { useState } from "react";
import { MdOutlineShoppingBag } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";

// import NavDropdown from "react-bootstrap/NavDropdown";

import { Link, NavLink } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";
import { encodeCategoryForURL } from "../EncodedUrlComponent";
import SmallNavbar from "./SmallNavbar.jsx";
import logo from "../../images/logo.png";
import "./navbar.css";
import Search from "../../Pages/Search/Search.jsx";
import ScrollToTopLink from "../../Pages/ScroolToTop.jsx";
function NavbarComponent() {
  const {
    allMainCategory,
    setCategoryFilter,
    user,
    setCategoryFilterstudymaterial,
    setCategoryFiltersproject,
    handleScroolTop,
  } = useUserAuth();

  const [showSearch, setShowSearch] = useState(false);
  return (
    <div className="navbar-main-contaier">
      <div className="smallscreen-navbar">
        
        <SmallNavbar />     
      </div>

      <div className="largescreen-navbar" onClick={()=>(showSearch&&setShowSearch(false))}>
        <nav className="navbar">
          <div className="navbar-left">
            <Link to={"/"}>
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>
          <div className="navbar-center">
            <div className="dropdown">
              <button className="dropbtn button-color">
                <NavLink
                  to={"/coursepage"}
                  onClick={() => setCategoryFilter("")}
                >
                  {" "}
                  <span className="button-color">COURSES</span>
                </NavLink>
                {/* <span className="nav-icons">
                  <IoIosArrowDown />
                </span> */}
              </button>
              <div className="dropdown-content">
                <Link to={"/coursepage"} onClick={() => setCategoryFilter("")}>
                  ALL COURSES
                </Link>
                {allMainCategory &&
                  allMainCategory.map((e, i) => (
                    <Link
                      key={i}
                      to={`/coursecatgory/${encodeCategoryForURL(e.category)}`}
                      onClick={() =>
                        setCategoryFilter(encodeCategoryForURL(e.category))
                      }
                    >
                      {e.category}
                    </Link>
                  ))}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn button-color">
                {" "}
                <NavLink
                  to={"/project"}
                  onClick={() => setCategoryFiltersproject("")}
                >
                  {" "}
                  <span className="button-color">PROJECTS</span>
                </NavLink>
                {/* <span className="nav-icons">
                  <IoIosArrowDown />
                </span> */}
              </button>
              <div className="dropdown-content">
                <Link
                  to={"/project"}
                  onClick={() => setCategoryFiltersproject("")}
                >
                  ALL PROJECTS
                </Link>
                {allMainCategory &&
                  allMainCategory.map((e, i) => (
                    <Link
                      key={i}
                      to={`/projectcatgory/${encodeCategoryForURL(e.category)}`}
                      onClick={() =>
                        setCategoryFiltersproject(
                          encodeCategoryForURL(e.category)
                        )
                      }
                    >
                      {e.category}
                    </Link>
                  ))}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn button-color">
                <Link
                  to={"/studymaterial"}
                  onClick={() => setCategoryFilterstudymaterial("")}
                >
                  <span className="button-color">STUDYMATERIALS</span>{" "}
                </Link>

                {/* <span className="nav-icons">
                  <IoIosArrowDown />
                </span> */}
              </button>
              <div className="dropdown-content">
                <Link
                  to={"/studymaterial"}
                  onClick={() => setCategoryFilterstudymaterial("")}
                >
                  ALL STUDYMATERIALS
                </Link>
                {allMainCategory &&
                  allMainCategory.map((e, i) => (
                    <Link
                      key={i}
                      to={`/studymaterialcatgory/${encodeCategoryForURL(
                        e.category
                      )}`}
                      onClick={() =>
                        setCategoryFilterstudymaterial(
                          encodeCategoryForURL(e.category)
                        )
                      }
                    >
                      {e.category}
                    </Link>
                  ))}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn button-color">
                <Link to={"/about"}>
                  <span className="button-color">ABOUT</span>
                </Link>
              </button>
            </div>
            <div className="dropdown">
              <button className="dropbtn button-color">
                <Link to={"/contact"}>
                  <span className="button-color">CONTACT</span>
                </Link>
              </button>
            </div>

            <div className="dropdown button-color">
              {user && (
                <NavLink to={"/contact"}>
                  {" "}
                  <button className="dropbtn">
                    {" "}
                    <span className="button-color">MORE</span>{" "}
                    {/* <IoIosArrowDown className="button-color" /> */}
                  </button>{" "}
                </NavLink>
              )}
              <div className="dropdown-content">
                {/* <Link to={"/allvendor"}>INSTRUCTORS</Link> */}

                {user && <Link to={"/cart"}>CART</Link>}
                {user && <Link to={"/order"}>ORDERS</Link>}
                <Link to={"/about"}>ABOUT</Link>
                {user && <Link to={"/profile"}>PROFILE</Link>}
                {user === null && <Link to={"/login"}>LOGIN</Link>}
                {user === null && <Link to={"/register"}>REGISTER</Link>}
                {user && <Link to={"/payments"}>Payments</Link>}
                {/* {user && <Link to={"/tree"}>Tree</Link>} */}
                {user && <Link to={"/sales"}>Sales</Link>}

              </div>
            </div>
          </div>
          <div className="navbar-right">
            {/* <NavLink to="/search"> */}
            <span className="icon" onClick={() => setShowSearch(!showSearch)}>
              <i
                className="fa-solid fa-magnifying-glass fa-xl  login-icon"
                // style={{ color: "white" }}
              ></i>
            </span>
            {/* </NavLink> */}

            {user == null && (
              <Link to="/login">
                {" "}
                <span className=" login-icon">
                  <i>
                    <FaUserAlt className=" login-icon" />
                  </i>
                </span>
              </Link>
            )}
            {user && (
              <NavLink to={"/cart"}>
                {" "}
                <button className="cart-btn">
                  <MdOutlineShoppingBag className="react_icon_smartuter" />
                  <span className="cart-badge">{user && user.cart.length}</span>
                </button>
              </NavLink>
            )}
            {user && (
              // <NavLink to={"/profile"}>
              //   {" "}
              //   <button className="login-btn">
              //     <CgProfile className="react_icon_smartuter" />{" "}
              //   </button>
              // </NavLink>
              <Link onClick={handleScroolTop} to={"/profile"}>
                <button className="login-btn">
                  <CgProfile className="react_icon_smartuter" />{" "}
                </button>
              </Link>
            )}
            {/* {user == null && (
              <Link to={"/register"}>
                {" "}
                <button className="Sighup-btn">REGISTER</button>
              </Link>
            )} */}
          </div>
        </nav>
      </div>
      {showSearch && <Search  setShowSearch={setShowSearch}/>}
    </div>
  );
}

export default NavbarComponent;
    