import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const BankDetailsDialog = ({ open, onClose, onSave, initialData }) => {
  const [formValues, setFormValues] = useState({
    accountNumber: '',
    bankName: '',
    ifscCode: '',
  });
  // console.log(initialData);
  
  useEffect(() => {
    if (initialData) {
      setFormValues(initialData);
    } else {
      setFormValues({
        accountNumber: '',
        bankName: '',
        ifscCode: '',
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(formValues);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialData ? 'Edit Bank Details' : 'Enter Bank Details'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="accountNumber"
          label="Account Number"
          type="number"
          fullWidth
          value={formValues.accountNumber}
          onChange={handleChange}
      

        />
        <TextField
          margin="dense"
          name="bankName"
          label="Bank Name"
          type="text"
          fullWidth
          value={formValues.bankName}
          onChange={handleChange}
          className='no-transform'

        />
        <TextField
          margin="dense"
          name="ifscCode"
          label="IFSC Code"
          type="text"
          fullWidth
          value={formValues.ifscCode}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankDetailsDialog;
