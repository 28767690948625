import React from 'react';
import { Button, Container, IconButton, Paper, Typography } from '@mui/material';
import UpiDetailsDialog from './UpiDetailsDialog';
import { Delete, Edit } from '@mui/icons-material';

const UpiDetailsDisplay = ({handleOpenDialog,open,onClose,onSave,bankDetails, onDelete, onEdit,selectedUPIDetails}) => {


  return (
    <Container>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog(null)}>
        Enter UPI ID
      </Button>
      <UpiDetailsDialog open={open} onClose={onClose} onSave={onSave}  initialData={selectedUPIDetails}/>
      {bankDetails &&
        bankDetails.map((e, i) => (
          <Paper key={i} style={{ padding: 16, marginTop: 16 }}>
            <Typography variant="h6">UPI ID Details</Typography>
            <Typography
              variant="body1"
              sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              UPI ID: {e.upiid}
            </Typography>
            {/* Edit and Delete buttons */}
            <div style={{ marginTop: 10 }}>
              <IconButton color="primary" onClick={() => handleOpenDialog(e)}>
                <Edit />
              </IconButton>
              <IconButton color="secondary" onClick={() => onDelete(i)}>
                <Delete />
              </IconButton>
            </div>
          </Paper>
        ))}
    </Container>
  );
};

export default UpiDetailsDisplay;
