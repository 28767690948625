import React from 'react';
import { Button, Container, IconButton, Paper, Typography, Grid } from '@mui/material';
import BankDetailsDialog from './BankDetailsDialog';
import { Delete, Edit } from '@mui/icons-material';

const BankDetailsDisplay = ({ handleOpenDialog, open, onClose, onSave, bankDetails, onDelete, selectedBankDetails }) => {
  return (
    <Container>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => handleOpenDialog(null)}
        sx={{ marginBottom: 2 }}
      >
        Enter Bank Details
      </Button>
      
      <BankDetailsDialog 
        open={open} 
        onClose={onClose} 
        onSave={onSave} 
        initialData={selectedBankDetails} 
      />
      
      {bankDetails && bankDetails.length > 0 ? (
        bankDetails.map((e, i) => (
          <Paper key={i} elevation={3} sx={{ padding: 2, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#06517a' }}>
                  Bank Details
                </Typography>
                <Typography variant="body1">
                  <strong>Account Number:</strong> {e.accountNumber}
                </Typography>
                <Typography variant="body1">
                  <strong>Bank Name:</strong> {e.bankName}
                </Typography>
                <Typography variant="body1">
                  <strong>IFSC Code:</strong> {e.ifscCode}
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={4} container justifyContent="flex-end">
                <IconButton color="primary" onClick={() => handleOpenDialog(e)} title="Edit">
                  <Edit />
                </IconButton>
                <IconButton color="secondary" onClick={() => onDelete(i)} title="Delete">
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))
      ) : (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          No bank details available. Please enter bank details to display.
        </Typography>
      )}
    </Container>
  );
};

export default BankDetailsDisplay;
