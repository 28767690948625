import React from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { FaDownload } from "react-icons/fa6";
import "./order.css";
import { useEffect } from "react";
import NoData from "../../images/NoData.gif";
import { Link } from "react-router-dom";
import AddCart from "../../images/AddCart.gif";

const Order = () => {
  const { user, token, getUserData } = useUserAuth();
  useEffect(() => {
    getUserData(token);
  }, []);

  // console.log("user", user);

  const getInvoice = async (paymentId, user, orderId) => {
    // console.log(paymentId);

    const tempuser = {
      name: user.name,
      email: user.email,
    };

    try {
      const response = await fetch(
        "https://smartuter.com/api/enduser/download/invoice/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentId: paymentId,
            user: tempuser,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${orderId}invoice.pdf`; // Specify the file name here
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error as needed
    }
  };
  // console.log("user.orders", user && user.orders);
  return (
    <>
      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">Orders</h3>
          <p className="course-section-card-para">
            Enjoy a seamless learning experience with our easy-to-use course
            enrollment system.
          </p>

          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={""}>
              <span className="header-links underline-headerlink">Orders</span>
            </Link>
          </div>
        </div>
        <img src={AddCart} />
      </div>

      <div className="order-head">
        {/* <h1 className="order-h1">Orders</h1> */}

        <div className="order-container">
          {user && user.orders && user.orders.length > 0 ? (
            user.orders
              .sort((a, b) => b.timestamp - a.timestamp)
              .map((order, index) => (
                <div className="order-card" key={index}>
                  <img
                    src={order.imageUrl}
                    alt={order.postName}
                    className="order-card-img"
                    onError={(e) => {
                      e.target.src =
                        "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                    }}
                  />
                  <div className="order-card-content">
                    <h3 className="order-card-title">{order.postName}</h3>
                    <h6>
                      <span>{order.mainCategory}</span>
                    </h6>
                    <span className="card-description">
                      {order.tags &&
                        order.tags.map((tag, index) => (
                          <span key={index}>{tag.text} </span>
                        ))}
                    </span>
                    <p className="order-card-price">₹{order.Price}</p>

                    {/* Download buttons */}
                    {order.zipUrl && (
                      <a href={order.zipUrl} download>
                        <button className="order-download-btn">
                          <FaDownload className="order-download-icon" />{" "}
                          Download ZIP
                        </button>
                      </a>
                    )}
                    {order.DocumentUrl && (
                      <a href={order.DocumentUrl} download>
                        <button className="order-download-btn">
                          <FaDownload className="order-download-icon" />{" "}
                          Download Document
                        </button>
                      </a>
                    )}
                    {/* <p className="order-id">Order Id:{order.ID}</p> */}
                    <p
                      onClick={() => {
                        getInvoice(order.paymentId, user, order.orderId);
                      }}
                    >
                      <button className="order-download-btn">
                        <FaDownload className="order-download-icon" />
                        invoice
                      </button>
                    </p>
                  </div>
                </div>
              ))
          ) : (
            <div className="no-course-found-container">
              <p className="no-course-found-text">
                Sorry..!, we couldn't find any result.
              </p>

              <img src={NoData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Order;
