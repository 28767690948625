import React from "react";
import { HiAcademicCap } from "react-icons/hi";
import { GrUserWorker } from "react-icons/gr";
import { LuFileBadge } from "react-icons/lu";
import coursesimg from "../images/coursesimg.gif";

import "./about.css";
import FaqAccordion from "./Faq";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div>
        <div className="all-courses-main">
          <div>
            <h3 className="course-section-card-head">About Us</h3>
            <p className="course-section-card-para">
              Welcome to our Smartuter Enhance your skills with best Online
              courses.
            </p>

            <div className="header-navitems">
              <Link to={"/"}>
                <span className="header-links">Home</span>
              </Link>
              <span className="mx-1">{">"}</span>
              <Link to={"/about"}>
                <span className="header-links underline-headerlink">
                  About us
                </span>
              </Link>
            </div>
          </div>
          <img src={coursesimg} alt="courseImg" />
        </div>
        <div className="about-container">
          {/* <h2 className="about-head">About Us</h2>
        <p className="about-para">
          We’re on a mission to deliver engaging, curated courses at a
          reasonable price.
        </p> */}

          {/* <div className="all-courses-main-About">
      <h3 className="course-section-card-head">ALL STUDYMATERIALS</h3>
      <p className="course-section-card-para">
      Write an introductory description of the category.
      </p>
      
      </div> */}

          <div className="about-us">
            <div className="about-image-container">
              <img
                src="https://educrat-react.vercel.app/assets/img/masthead/1.png"
                alt="imag-1"
                className="about-image-1"
              />
              <div className="vertical-images">
                <img
                  src="https://educrat-react.vercel.app/assets/img/masthead/2.png"
                  alt="imag-1"
                  className="about-image-2"
                />
                <img
                  src="https://educrat-react.vercel.app/assets/img/masthead/3.png"
                  alt="imag-1"
                  className="about-image-3"
                />
              </div>
            </div>
            <div className="about-text-container">
              <h2 className="about-head">About Us</h2>

              <h4 className="about-para">
                We’re on a mission to deliver engaging, curated courses at a
                reasonable price.
              </h4>

              <span className="about-text-para-1">
                Welcome to our Smartuter Enhance your skills with best Online
                courses You can start and finish one of these popular courses in
                under a day – for free! Check out the list below.. Take the
                course for free
              </span>
              <span className="about-text-para-2">
                Neque convallis a cras semper auctor. Libero id faucibus nisl
                tincidunt egetnvallis a cras semper auctonvallis a cras semper
                aucto. Neque convallis a cras semper auctor. Liberoe convallis a
                cras semper atincidunt egetnval
              </span>

              <div className="start-learning-button-container">
                <a href="#" className="start-learning-button">
                  Start Learning{" "}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="about-why-learn-section">
          <h1 className="why-learn-head">Why Learn With Our Courses?</h1>
          <p className="why-learn-para">
            Learning helps us to keep pursuing our personal and professional
            development goals.
          </p>

          <div className="why-learn-card-container">
            <div className="why-learn-card">
              <GrUserWorker alt="card-icon" className="font-icon" />
              <h3 className="why-learn-head">1. Learn</h3>
              <p className="why-learn-para-1">
                Learning is the process of acquiring new understanding,
                knowledge, behaviors, skills, values, attitudes, and
                preferences.{" "}
              </p>
            </div>

            <div className="why-learn-card">
              <HiAcademicCap alt="card-icon" className="font-icon" />
              <h3 className="why-learn-head">2. Read</h3>
              <p className="why-learn-para-1">
                Reading is the process of taking in the sense or meaning of
                letters, symbols, etc., especially by sight or touch
              </p>
            </div>

            <div className="why-learn-card">
              <LuFileBadge alt="card-icon" className="font-icon" />
              <h3 className="why-learn-head">3. Write</h3>
              <p className="why-learn-para-1">
                A writing system uses a set of symbols and rules to encode
                aspects of spoken language, such as its lexicon and syntax.
              </p>
            </div>
          </div>
        </div>
        <FaqAccordion />
      </div>
    </>
  );
};

export default About;
