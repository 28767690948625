import React from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Messages from "../images/Messages.gif";

import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "./ContactNew.css";
import { Link } from "react-router-dom";
import { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission 

    try {
      if (email==="" || name==="" || message==="") {
        
        return toast.error("Missing Details" ,{
          duration: 6000,
        });
      }

      const response = await axios.post(
        "https://smartuter.com/api/enduser/contactus",
        {
          name,
          email,
          message,
        } 
      );
      console.log("Response:", response.data);
      toast.success(response.data.msg, {
        duration: 6000,
      });
      // Optionally clear the form or show a success message
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.log(error);
      
      toast.error(
        error.msg,
        {
          duration: 6000,
        }
      );
      // Optionally show an error message to the user
    }
  };

  return (
    <>
      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">Contact Us</h3>
          <p className="course-section-card-para">
            Welcome to our Smartuter Enhance your skills with best Online
            courses.
          </p>

          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={"/contact"}>
              <span className="header-links underline-headerlink">
                Contact Us
              </span>
            </Link>
          </div>
        </div>
        <img src={Messages} alt="message" />
      </div>

      <div className="contact-us-section">
        <div className="contactinfo-send-mail">
          <div className="contact-info">
            <h1 className="contact-info-head">Keep In Touch With Us.</h1>

            <div className="fao-icon-text">
              <div className="icon-container">
                <FontAwesomeIcon icon={faEnvelope} className="fao-icon" />{" "}
              </div>
              <span>
                Email:
                <a href="mailto:support@smartuter.com">support@smartuter.com</a>
              </span>
            </div>
            <div className="fao-icon-text">
              <div className="icon-container">
                <FontAwesomeIcon icon={faPhone} className="fao-icon" />
              </div>{" "}
              <span>
                <a
                  href={`tel:9182774794`}
                  style={{ color: "#007bff", textDecoration: "underline" }}
                >
                  Phone: +91 9876543210
                </a>
              </span>
            </div>
            <div className="fao-icon-text">
              <div className="icon-container">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="fao-icon" />
              </div>
              <span className="address-text">
                Address: Hyderabad, Telangana, 500039.
              </span>{" "}
            </div>
          </div>

          <div className="send-message-section">
            <h1 className="send-message-head">Send a Message</h1>

            <form className="message-form" onSubmit={handleSubmit}>
              <label htmlFor="name">Name</label>
              <input
                id="name"
                type="text"
                placeholder="ex: Smartuter"
                className="input-name no-transform"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <br />
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="ex: support@smartuter.com"
                className="input no-transform"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <label htmlFor="message">Message...</label>
              <textarea
                id="message"
                placeholder="Enter Your Message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="no-transform"
              ></textarea>
              <br />
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
