import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import Tree from "react-d3-tree";
import "../Tree/tree.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import coursesimg from "../../images/coursesimg.gif";

const Sales = () => {
  const { user } = useUserAuth();
  const { _id, name } = user !== null && user;
  const [treeData, setTreeData] = useState([]);

  const fetchTreeData = async (_id, name) => {
    try {
      
      const response = await fetch(
        "https://smartuter.com/api/enduser/getTree",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: _id }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const data = result.userTree;
        setTreeData(data);
      } else {
        console.error("Failed to fetch tree data");
      }
    } catch (error) {
      console.error("Error fetching tree data:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (_id && name) {
      fetchTreeData(_id, name);
    }
  }, [_id, name]);

  console.log(treeData);

  return (
    <>
      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">Sales</h3>

          <p className="course-section-card-para">
            Your intuitive reference Sales, guiding you to what you need.
          </p>

          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={""}>
              <span className="header-links underline-headerlink">Sales</span>
            </Link>
          </div>
        </div>
        <img src={coursesimg} alt="courseImg" />
      </div>

      <div>
        <div className="parent-referral-card">
          <h2>{name }:{treeData &&
          treeData.length } </h2>
          <div className="referral-list">
            {treeData &&
              treeData.map((referral, index) => (
                <div className="referral-pill" key={index}>
                  {`${referral.name} (${
                    referral.referralCount === undefined
                      ? 0
                      : referral.referralCount
                  })`},
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sales;
