import React, { useRef, useState } from "react";
import profie from "../../images/profile.png";
import { useUserAuth } from "../../Context/UseAuthContext";
import "./profile.css";
import Cookies from "js-cookie";
import axios from "axios";
import HeaderProfile from "../../images/HeaderProfile.gif";
import { BiSolidEditAlt } from "react-icons/bi";

import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import About from "../About";

import { Switch } from "@mui/material";
import BankDetailsDisplay from "./BankDetails/BankDetailsDisplay";
import UpiDetailsDisplay from "./BankDetails/UpiDetailsDisplay";
import { Link } from "react-router-dom";
const label = { inputProps: { "aria-label": "Switch demo" } };
const Profile = () => {
  const { user, getUserData, setUser, token, navigate, TOKEN_KEY } =
    useUserAuth();
  const [updatevalue, setUpdatevalue] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateNumber, setUpdateNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const fileInputRef = useRef(null);
  //bank
  const [dialogOpen, setDialogOpen] = useState(false);
  const [upidialogOpen, setupiDialogOpen] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);
  // Edit or Add Bank Details
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);
  const [selectedUPIDetails, setSelectedUPIDetails] = useState(null);
  const handleOpenDialog = (bankDetails) => {
    setSelectedBankDetails(bankDetails);
    setDialogOpen(!dialogOpen);
  };
  const handleOpenUpiDialog = (upiDetails) => {
    setSelectedUPIDetails(upiDetails);
    setupiDialogOpen(!upidialogOpen);
  };

  const handleSaveUPIDetails = async (details) => {
    // e.preventDefault();
    const id = user._id;

    // try {
    //   const response = await axios.patch(
    //     `https://smartuter.com/api/enduser/edit/${id}`,
    //     {
    //       upi: [...user.upi, e],
    //     }
    //   );
    //   getUserData(token);
    // } catch (error) {
    //   console.error("Error updating upi details:", error);
    //   // setMessage('An error occurred while updating bank details.');
    // }

    if (selectedUPIDetails) {
      // Edit logic
      const updatedUPIDetails = user.upi.map((upiDetail) =>
        upiDetail.upiid === selectedUPIDetails.upiid ? details : upiDetail
      );
      try {
        await axios.patch(`https://smartuter.com/api/enduser/edit/${id}`, {
          upi: updatedUPIDetails,
        });
        getUserData(token);
      } catch (error) {
        console.error("Error updating upi details:", error);
      }
    } else {
      // Add logic
      try {
        await axios.patch(`https://smartuter.com/api/enduser/edit/${id}`, {
          upi: [...user.upi, details],
        });
        getUserData(token);
      } catch (error) {
        console.error("Error adding upi details:", error);
      }
    }
    setSelectedUPIDetails(null);
  };

  const handleDeleteUPIDetails = async (index) => {
    const updatedUPIDetails = user && user.upi.filter((_, i) => i !== index);
    const id = user && user._id;
    try {
      await axios.patch(`https://smartuter.com/api/enduser/edit/${id}`, {
        upi: updatedUPIDetails,
      });
      getUserData(token);
    } catch (error) {
      console.error("Error deleting upi details:", error);
    }
  };
  const handleSaveDetails = async (details) => {
    const id = user._id;

    if (selectedBankDetails) {
      // Edit logic
      const updatedBankDetails = user.bankDetails.map((bankDetail) =>
        bankDetail.accountNumber === selectedBankDetails.accountNumber
          ? details
          : bankDetail
      );
      try {
        await axios.patch(`https://smartuter.com/api/enduser/edit/${id}`, {
          bankDetails: updatedBankDetails,
        });
        getUserData(token);
      } catch (error) {
        console.error("Error updating bank details:", error);
      }
    } else {
      // Add logic
      try {
        await axios.patch(`https://smartuter.com/api/enduser/edit/${id}`, {
          bankDetails: [...user.bankDetails, details],
        });
        getUserData(token);
      } catch (error) {
        console.error("Error adding bank details:", error);
      }
    }
    setSelectedBankDetails(null);
  };
  const handleDeleteBankDetails = async (index) => {
    const updatedBankDetails =
      user && user.bankDetails.filter((_, i) => i !== index);
    const id = user && user._id;
    try {
      await axios.patch(`https://smartuter.com/api/enduser/edit/${id}`, {
        bankDetails: updatedBankDetails,
      });
      getUserData(token);
    } catch (error) {
      console.error("Error deleting bank details:", error);
    }
  };

  const handleEditName = (v) => {
    setUpdatevalue(v);
  };

  const handleUpdate = () => {
    const id = user._id;
    let payload;
    if (updatevalue === "name" && updateName !== "") {
      payload = { name: updateName };
    } else if (updatevalue === "email" && updateEmail !== "") {
      payload = { email: updateEmail };
    } else if (updatevalue === "number" && updateNumber !== "") {
      payload = { number: updateNumber };
    } else if (profileUrl !== "") {
      payload = { profileUrl: profileUrl };
    }

    setProfileUrl("");

    fetch(`https://smartuter.com/api/enduser/edit/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdatevalue("");
        setProfileUrl("");
        getUserData(token);
      })
      .catch((err) => console.log(err));
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(
      "https://smartuter.com/api/enduser/uploadimage",
      {
        method: "POST",
        body: formData,
      }
    );
    if (response.ok) {
      const data = await response.json();
      setProfileUrl(data.fileUrl);
    } else {
      console.error("Error uploading file:", response.statusText);
      throw new Error("Upload failed");
    }
    // Handle the selected file (e.g., upload or display it)
  };
  const handleLogout = () => {
    Cookies.remove(TOKEN_KEY);
    localStorage.removeItem("referredLink");
    localStorage.removeItem("referralCode");
    localStorage.clear();
    setUser(null);
    navigate("/");
  };

  if (user == null) {
    return <h1>...Profile</h1>;
  }
  return (
    <div>
      <div className="all-courses-main">
        <div>
          <h3 className="course-section-card-head">Profile</h3>
          <p className="course-section-card-para">
            Manage your preferences and Bank Details history effortlessly
            through your profile.
          </p>

          <div className="header-navitems">
            <Link to={"/"}>
              <span className="header-links">Home</span>
            </Link>
            <span className="mx-1">{">"}</span>
            <Link to={""}>
              <span className="header-links underline-headerlink">Profile</span>
            </Link>
          </div>
        </div>
        <img src={HeaderProfile} alt="headerprofile" />
      </div>

      <div className="profile_main">
        <div className="profile-main-container">
          <div className="profile-main-sub-con">
            <div className="profile-main-img">
              <div>
                <img
                  src={user.profileUrl ? user.profileUrl : profie}
                  alt="Profile"
                  className=""
                  onClick={() => fileInputRef.current.click()}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {profileUrl && (
                  <button className="profile-update-btn" onClick={handleUpdate}>
                    Update
                  </button>
                )}
                <h3>{user.name}</h3>
                <span>{user.email} </span>
              </div>
            </div>
            <div className="profile-input-box">
              <div className="profile-input-name">
                <h4 className="uppercase no-transform">
                  <FaUserAlt /> : {user.name}
                </h4>
                <div>
                  {updatevalue === "name" ? (
                    <>
                      <input
                        placeholder="Enter Your Name"
                        onChange={(e) => setUpdateName(e.target.value)}
                        className="profile-input no-transform"
                      />
                      <button className="profile-btn" onClick={handleUpdate}>
                        Update
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="profile-edit-btn"
                        onClick={() => handleEditName("name")}
                      >
                        <BiSolidEditAlt />
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="profile-input-name">
                <h4 className="">
                  <IoMdMail /> : {user.email}
                </h4>
                <div>
                  {updatevalue === "email" ? (
                    <>
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        onChange={(e) => setUpdateEmail(e.target.value)}
                        className="profile-input no-transform"
                      />
                      <button className="profile-btn" onClick={handleUpdate}>
                        Update
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="profile-edit-btn"
                        onClick={() => handleEditName("email")}
                      >
                        <BiSolidEditAlt />
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="profile-input-name">
                <h4>
                  <FaPhoneAlt /> : {user.number}
                </h4>
                <div>
                  {updatevalue === "number" ? (
                    <>
                      <input
                        type="number"
                        placeholder="Enter Your number"
                        onChange={(e) => setUpdateNumber(e.target.value)}
                        className="profile-input no-transform"
                      />
                      <button className="profile-btn" onClick={handleUpdate}>
                        Update
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="profile-edit-btn"
                        onClick={() => handleEditName("number")}
                      >
                        <BiSolidEditAlt />
                      </button>
                    </>
                  )}
                </div>
              </div>
              <p className="profile-dummy-text">
                Manage your personal information, view your activity, and
                customize your preferences for a tailored experience.
              </p>
              <button className="profile-logout-btn" onClick={handleLogout}>
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bank-upi-div">
        <div
          className="bank-upi-sub-div-switch"
          style={{ textAlign: "center" }}
        >
          <span
            className="bank-upi-sub-div"
            style={{ borderBottom: !checkedValue && "2px solid #06517a" }}
          >
            BANK DETAILS
          </span>{" "}
          <Switch
            {...label}
            onChange={(e) => setCheckedValue(e.target.checked)}
          />{" "}
          <span
            className="bank-upi-sub-div"
            style={{ borderBottom: checkedValue && "2px solid #06517a" }}
          >
            UPI ID
          </span>
        </div>

        {!checkedValue && (
          <div className="bank-details-card">
            <div style={{ textAlign: "left" }}>
              <BankDetailsDisplay
                handleOpenDialog={handleOpenDialog}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSave={handleSaveDetails}
                bankDetails={user && user.bankDetails}
                onDelete={handleDeleteBankDetails}
                selectedBankDetails={selectedBankDetails}
              />
            </div>
          </div>
        )}
        {/* </div> */}

        {checkedValue && (
          <div style={{ textAlign: "left" }}>
            <UpiDetailsDisplay
              handleOpenDialog={handleOpenUpiDialog}
              open={upidialogOpen}
              onClose={() => setupiDialogOpen(false)}
              onSave={handleSaveUPIDetails}
              bankDetails={user && user.upi}
              onDelete={handleDeleteUPIDetails}
              selectedUPIDetails={selectedUPIDetails}
            />
          </div>
        )}
      </div>
      {/* <About /> */}
    </div>
  );
};

export default Profile;
