import React, { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import cartempty from "../../images/cartempty.png";
import { toast } from "react-hot-toast";
import axios from "axios";
import "./cart.css";
import { Link, NavLink } from "react-router-dom";
import AddCart from '../../images/AddCart.gif';



export const Cart = () => {
  const { user, getUserData, token, navigate } = useUserAuth();

  const handleRemoveItem = (ID) => {
    const id = user._id;
    axios
      .delete(`https://smartuter.com/api/enduser/delete/${id}`, {
        data: { ID },
      })
      .then((res) => {
        if (res.data.msg === true) {
          toast.success("Course has been removed from the Cart");
          getUserData(token);
        }
      })
      .catch((err) => console.log(err));
  };

  let totalPrice = 0;
  if (user && user.cart) {
    for (const item of user.cart) {
      // Assuming each item has a price property
      totalPrice += item.Price;
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const handleCheckout = async () => {
    const id = user._id;
    const cartData = user.cart;
    try {
      const saleData = {
        cart: cartData,
        userId: id,
      };

      const response = await fetch("https://smartuter.com/api/enduser/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(saleData),
      });

      if (!response.ok) {
        toast.success(`${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !data.order_id) {
        throw new Error("Order ID not received from the server");
      }

      const options = {
        key: process.env.RAZORPAY_API_KEY,
        order_id: data.order_id,
        handler: async function (response) {
          try {
            const validateResponse = await fetch(
              "https://smartuter.com/api/enduser/paymentCapture",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(response),
              }
            );

            if (!validateResponse.ok) {
              throw new Error(
                `Payment capture error: ${validateResponse.statusText}`
              );
            }

            const responseData = await validateResponse.json();

            user.cart = [];
            navigate("/");
          } catch (error) {
            toast.error(
              "Payment validation failed. Please try again if money is deducated contact admin for refund."
            );
          }
        },
      };

      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast.error(`Payment failed: ${response.error.description}`);
      });

      rzp1.on("payment.cancel", function (response) {
        toast.error("Payment canceled by the user..");
      });

      rzp1.open();
    } catch (error) {
      console.error("handleCheckout Error:", error);
      toast.error(`Oops! Something went wrong: ${error.message}`);
    }
  };

  return (
    <>
    
   <div className="all-courses-main">
        <div>
        <h3 className="course-section-card-head">Cart</h3>
      <p className="course-section-card-para">
      We're on a mission to deliver engaging, curated courses at a
              reasonable price.     </p>
      
<div className="header-navitems">
<Link to={"/"}><span className="header-links">Home</span></Link>
        <span className="mx-1">{">"}</span>
        <Link to={""}><span className="header-links underline-headerlink">Cart</span></Link>
</div>
        </div>
        <img src={AddCart} />
      </div>


    <div className="cart-main-container">
      {user && user.cart && user.cart.length > 0 ? (
        <div className="cart-page">
          {/* <div className="cart-page-sub">
            <h1>Course Cart</h1>
            <p>
              We're on a mission to deliver engaging, curated courses at a
              reasonable price.
            </p>
          </div> */}

          <table>
            <thead>
              <tr className="tabel-head-bg">
                {/* <th className='table-head-0' ></th> */}
                <th className="table-head-1">Product</th>
                <th className="table-head-2">Price</th>
                <th className="table-head-3">Remove</th>
              </tr>
            </thead>
            <tbody>
              {user.cart.map((e, i) => (
                <tr key={i}>
                  <td className="img-table">
                    <div className="product-info">
                      <img src={e.imageUrl} alt="Product" />
                      <p>{e.postName}</p>
                    </div>
                  </td>
                  <td>₹{e.Price}</td>
                  <td
                    onClick={() => handleRemoveItem(e.ID)}
                    className="table-delete"
                  >
                    <i className="fa-solid fa-xmark fa-xl"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="cart-total-container">
            <h4>Cart Totals</h4>
            <div className="cart-total-price cart-total-price-1">
              <h6>Subtotal</h6> <h6>₹{totalPrice}.00</h6>
            </div>
            <div className="cart-total-price">
              <h6>Total</h6> <h6>₹{totalPrice}.00</h6>
            </div>
            <div className="cart-checkout">
              <button onClick={handleCheckout}>Proceed To Checkout</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-cart">
                    <img src={AddCart} alt="CartEmpty" />

          <h1>Your Cart <span className="text-danger">Empty</span></h1>
          <p>Must add items on the cart befoure you proceed to check out.</p>
          <button className="backto-return-btn">
            <NavLink to={"/"}>RETURN TO COURSES</NavLink>
          </button>
        </div>
      )}
    </div>
      </>
  );
};
