import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const UpiDetailsDialog = ({ open, onClose, onSave ,initialData}) => {
  const [formValues, setFormValues] = useState({
    upiid: '',
  });

    
  useEffect(() => {
    if (initialData) {
      setFormValues(initialData);
    } else {
      setFormValues({ upiid: ''});
    }
  }, [initialData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(formValues);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialData ? 'Edit UPI ID' : 'Enter UPI ID'}</DialogTitle>
     
      <DialogContent>
        <TextField
          margin="dense"
          name="upiid"
          label="UPI ID"
          type="text"
          fullWidth
          value={formValues.upiid}
          onChange={handleChange}
        />
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpiDetailsDialog;
